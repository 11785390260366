import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Integer } from "aws-sdk/clients/apigateway";

@Injectable({ providedIn: "root" })
export class NewApiService {
  // protected basePath = 'https://api.nisshoca.com:8443/ProcessSmart';
  protected basePath = "https://api.nisshoca.com:8443/ProcessSmart";

  constructor(private http: HttpClient) {}

  getViewPurchaseOrder(uname_login: string) {
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
    });
    return this.http.get(
      `${this.basePath}/getViewPurchaseOrder/54/id?l=10000&o=DESC&p=1&jn=13285-10`,
      { headers: httpHeaders }
    );
  }

  getViewWalkThrough(typeId, processNum, uId: any, paramJobnum = ""): any {
    console.log("itemsList", typeId, processNum, uId);
    if (uId == undefined) {
      uId = 0;
    }
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
    });
    return this.http.get(
      `${
        this.basePath
      }/formWalkThrough/${typeId}/id?l=10000&o=DESC&p=${processNum}&f=${0}&jn=${encodeURIComponent(
        paramJobnum
      )}`,
      { headers: httpHeaders }
    );
  }

  getProcessnum(typeId): any {
    console.log("working", typeId);
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
    });
    return this.http.get(
      `${this.basePath}/formWalkThrough/${typeId}/id?l=10000&o=DESC`,
      { headers: httpHeaders }
    );
  }

  getWorkOrders(typeId): any {
    const token: any = localStorage.getItem("currentUser");
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
    });
    return this.http.get(
      `${this.basePath}/formWalkThrough/${typeId}/id?l=10000&o=DESC`,
      { headers: httpHeaders }
    );
  }

  deleteWorkOrder(work_order: Array<String>) {
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
      "Content-Type": "application/json",
    });
    var body = {
      isDeleted: true,
      ids: work_order,
    };
    return this.http.post(
      `${this.basePath}/work-order/delete-status?ids=${work_order}&isDeleted=true`,
      JSON.stringify(body),
      { headers: httpHeaders }
    );
  }

  getJobNumberList() {
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
      "Content-Type": "application/json",
    });

    return this.http.get(`${this.basePath}/getJobNumberList`, {
      headers: httpHeaders,
    });
  }

  getDeletedWorkOrder() {
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
      "Content-Type": "application/json",
    });

    return this.http.get(`${this.basePath}/get_deleted_work_order_list`, {
      headers: httpHeaders,
    });
  }

  retrieveWorkOrder(id: Integer) {
    const token: any = localStorage.getItem("currentUser");
    console.log("data", JSON.parse(token).apiKey);
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${JSON.parse(token).apiKey}`,
      "Content-Type": "application/json",
    });

    return this.http.post(
      `${this.basePath}/work-order/retrieve?id=${id}`,
      {},
      {
        headers: httpHeaders,
      }
    );
  }
}
